@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Righteous&display=swap");

:root {
  --black: #000000;
  --white: #ffffff;
  --whitesmoke: #dedede;
  --orange: #b17a50;
  --darkgray: #868686;
}
* {
  margin: 0;
  padding: 0;
  font-family: "Lexend", sans-serif;
}
html {
  /* background-color: var(--whitesmoke); */
}
body {
  font-size: 1em;
  overflow-x: hidden;
  /* font-family: "Righteous", sans-serif; */
}
